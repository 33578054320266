export enum ViewStyle {
  SinglePage = 0,
  DoublePage,
  LongStrip,
}

export enum ReadStyle {
  LTR = 0,
  RTL,
}

export enum HeaderStyle {
  Hidden = 0,
  Shown,
}

export type PageType = {
  src: string;
  text: string | null;
  value: number;
  spread: boolean;
  component?: string;
  componentProps?: Record<string, any>;
};

export enum HistoryMode {
  None = 0,
  Both,
  Buttons,
}

export enum TurnPageMode {
  Directional = 0,
  Forward,
  Never,
}

export enum CursorHints {
  None = 0,
  Overlay,
  Cursor,
}

export enum ProgressMode {
  None = 0,
  LightBar,
  Normal,
}

export enum ProgressSide {
  Bottom = 0,
  Left,
  Right,
}

export enum ScrollPageTurnMode {
  Disabled = 0,
  MouseWheel,
  Keyboard,
  Both,
}
