import { Headers } from "./generic";

import type { Result } from "../types/result";
import type { CustomListEntity } from "../types/customList";
import { assertSuccess } from "../core/assert";
import { paramsParser } from "../core/paramsParser";
import axios from "../core/axios";

export class CustomList {
  static AsUser(auth: string) {
    return AsUserStatic(auth);
  }

  static async get(id: string, extended: boolean = true, auth?: string) {
    const resp = await axios<Result<CustomListEntity>>(
      `/list/${id}` +
        paramsParser(extended ? { includes: ["title", "user"] } : {}),
      {
        headers: auth ? Headers.Bearer(auth) : undefined,
        responseType: "json",
      },
    );

    if (resp.status !== 200)
      throw new Error("There was an error while attempting to get CustomList.");

    return assertSuccess(resp.data).data;
  }
}

function AsUserStatic(auth: string) {
  return {
    async get(...args: [id: string, extended?: boolean]) {
      return CustomList.get(...args, auth);
    },
  };
}
