import type { Entity, Relation } from "./entity";
import type { AvailableLanguages } from "./locales";
import type { GenericListParams } from "./generic";

export interface CoverAttributes {
  // description: string;
  volume: string;
  locale: AvailableLanguages;
  fileName: string;
}

export type CoverEntity = Entity<"cover", CoverAttributes>;
export type CoverRelation = Relation<"cover_art", CoverAttributes>;

export const enum CoverOrderOptions {
  CREATEDAT = "createdAt",
  UPDATEDAT = "updatedAt",
  VOLUME = "volume",
}

export interface CoverListParams extends GenericListParams {
  titleIds?: string[];
  uploaders?: string[];
  volume?: string;
  locale?: AvailableLanguages;

  order?: { [key in CoverOrderOptions]?: "asc" | "desc" };
}

export interface CoverDetails {
  locale: AvailableLanguages;
  volume: string | null;
}
